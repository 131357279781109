export enum StarType {
    BRONZE = 'bronze',
    SILVER = 'silver',
    GOLD = 'gold',
}

export interface ISendStarPayload {
    star_type: StarType;
    survey_id: number;
}
