'use client';
import dynamic from 'next/dynamic';

import { createContext, memo, NamedExoticComponent, ReactNode, useContext, useState, MouseEvent } from 'react';
import { CSSTransition } from 'react-transition-group';

import { IModalContext, PopupDirection, PopupTypes } from '@/lib/types/index';
import { useSwipeable } from 'react-swipeable';

import UpRating from '@/components/PopupModals/UpRating/UpRating';
import SponsorStars from '@/components/PopupModals/SponsorStars/SponsorStars';
import SkeletonRemoveFromCompetition from '@/components/PopupModals/RemoveFromCompetition/SkeletonRemoveFromCompetition';
import SkeletonSetUserSiteLink from '@/components/PopupModals/SetUserSiteLink/SkeletonSetUserSiteLink';
import SkeletonUpRating from '@/components/PopupModals/UpRating/SkeletonUpRating';

const RemoveFromCompetitionLazy = dynamic(
    () => import('@/components/PopupModals/RemoveFromCompetition/RemoveFromCompetition'),
    {
        loading: () => <SkeletonRemoveFromCompetition />,
    }
);
const RejectParticipantLazy = dynamic(() => import('@/components/PopupModals/RejectParticipant/RejectParticipant'));
const SetUserSiteLinkLazy = dynamic(() => import('@/components/PopupModals/SetUserSiteLink/SetUserSiteLink'), {
    loading: () => <SkeletonSetUserSiteLink />,
});
const UpRatingLazy = dynamic(() => import('@/components/PopupModals/UpRating/UpRating'), {
    loading: () => <SkeletonUpRating />,
});

export const ModalContext = createContext<IModalContext>({
    isOpenPopup: false,
    togglePopup: () => {},
});

let { Provider } = ModalContext;

interface IModalContextProvider {
    children: ReactNode;
}

export const _ModalContextProvider: NamedExoticComponent<IModalContextProvider> = memo(({ children }) => {
    const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
    const [popupType, setPopupType] = useState<PopupTypes>();
    const [popupPayload, setPopupPayload] = useState<any | null>();
    const [direction, setDirection] = useState<PopupDirection>(PopupDirection.BottomToTop);

    const closeHandler = (event: MouseEvent<HTMLDivElement>) => {
        const target = event.target as HTMLElement;

        if (!target.closest('.popup-modal')) {
            togglePopup(false);
        }
    };

    const togglePopup = (
        flag: boolean,
        popupType: PopupTypes | null = null,
        payload: any = null,
        direction: PopupDirection = PopupDirection.BottomToTop
    ) => {
        setIsOpenPopup(flag);
        if (!flag) {
            const $popupWrapper = document.querySelector('.popup-wrapper');

            const timerId = setTimeout(() => {
                $popupWrapper?.classList.remove('popup-wrapper__active');
                clearTimeout(timerId);
            }, 500);
        } else {
            const $popupWrapper = document.querySelector('.popup-wrapper');
            $popupWrapper?.classList.add('popup-wrapper__active');

            setDirection(direction);
        }

        if (popupType) setPopupType(popupType);
        if (!flag) setPopupPayload(null);
        if (payload) setPopupPayload(payload);
    };

    const renderPopup = () => {
        switch (popupType) {
            case PopupTypes.UpRating:
                return <UpRatingLazy participant={popupPayload} />;
            case PopupTypes.RejectParticipant:
                return <RejectParticipantLazy participant={popupPayload} />;
            case PopupTypes.SetUserSiteLink:
                return <SetUserSiteLinkLazy participant={popupPayload} />;
            case PopupTypes.RemoveFromCompetition:
                return <RemoveFromCompetitionLazy participant={popupPayload} />;
            case PopupTypes.SponsorStars:
                return <SponsorStars survey_id={popupPayload} />;
        }
    };

    const swipeHandlers = useSwipeable({
        onSwipedDown: () => togglePopup(false),
    });

    return (
        <Provider
            value={{
                isOpenPopup,
                togglePopup,
            }}
        >
            <div className={`popup-wrapper`} onClick={(event) => closeHandler(event)} {...swipeHandlers}>
                <CSSTransition
                    in={isOpenPopup}
                    timeout={{
                        enter: 500,
                        exit: 500,
                    }}
                    classNames={`${
                        direction === PopupDirection.BottomToTop ? 'popupTransitionBotTop' : 'popupTransitionTopBot'
                    }`}
                    unmountOnExit
                >
                    <div
                        className={`popup-modal ${
                            direction === PopupDirection.BottomToTop ? 'popup-bt-top' : 'popup-top-bt'
                        }`}
                    >
                        <div className={`popup-modal__content`}>{renderPopup()}</div>
                    </div>
                </CSSTransition>
            </div>
            {children}
        </Provider>
    );
});

export const useModal = () => useContext(ModalContext);
