import { $api } from '@/api/api';
import { ISendStarPayload, StarType } from '../types/services/Star.type';
import { IApiResponse } from '../types/Api.type';

class starService {
    async getUserStarsList(competition_id: number): Promise<StarType[] | null> {
        return (await $api.get(`/stars?competition_id=${competition_id}`)).data;
    }

    async sendStar(payload: ISendStarPayload): Promise<IApiResponse> {
        return (await $api.put('/stars', payload)).data;
    }
}

export const StarService = new starService();
