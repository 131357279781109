import { ButtonHTMLAttributes, FC } from 'react';

import './button.scss';

import MiniLoader from '../MiniLoader/MiniLoader';

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    className?: string;
    clickHandler?: () => void;
    isLoading?: boolean;
    afterIcon?: React.ReactNode;
}

const Button: FC<IButton> = ({ afterIcon, text, className, clickHandler, isLoading, ...props }) => {
    return (
        <div className={`btn-base ${className ? className : ''}`}>
            <button className="btn" disabled={isLoading || props.disabled} onClick={clickHandler} {...props}>
                {isLoading ? (
                    <MiniLoader size={20} />
                ) : (
                    <>
                        {text}
                        {afterIcon && afterIcon}
                    </>
                )}
            </button>
        </div>
    );
};

export default Button;
