import { StarType } from '../types/services/Star.type';

export const SponsorStars = [
    {
        type: StarType.GOLD,
        color: '#ffd700',
        voteText: 'до +10% голосов',
    },
    {
        type: StarType.SILVER,
        color: '#FFFFFF',
        voteText: 'до +8% голосов',
    },
    {
        type: StarType.BRONZE,
        color: '#b87333',
        voteText: 'до +6% голосов',
    },
];
