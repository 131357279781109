'use client';

import dynamic from 'next/dynamic';
import { createContext, memo, NamedExoticComponent, ReactNode, useContext, useState } from 'react';
import { IGalleryContext } from '@/lib/types';
import { CSSTransition } from 'react-transition-group';
import { IParticipant, IGalleryPayload } from '@/lib/types/services/Competiton.type';
import MiniLoader from '@/components/UI/MiniLoader/MiniLoader';

import styles from '../components/PhotoGallery/photoGallery.module.scss';

const PhotoGalleryLazy = dynamic(() => import('@/components/PhotoGallery/PhotoGallery'), {
    loading: () => <MiniLoader />,
});

export const GalleryContext = createContext<IGalleryContext>({
    isOpenGallery: false,
    toggleGalleryHandler: () => {},
    galleryPayload: null,
});

let { Provider } = GalleryContext;

interface IGalleryContextProvider {
    children: ReactNode;
}

export const _GalleryContextProvider: NamedExoticComponent<IGalleryContextProvider> = memo(({ children }) => {
    const [isOpenGallery, toggleGallery] = useState<boolean>(false);
    const [galleryPayload, setGalleryPayload] = useState<IGalleryPayload | null>(null);

    const toggleGalleryHandler = (flag: boolean, payload?: IGalleryPayload) => {
        if (!flag) setGalleryPayload(null);
        if (flag && payload) setGalleryPayload(payload);

        toggleGallery(flag);
    };

    return (
        <Provider
            value={{
                isOpenGallery,
                toggleGalleryHandler,
                galleryPayload,
            }}
        >
            {children}

            <CSSTransition
                in={isOpenGallery}
                timeout={{
                    enter: 500,
                    exit: 500,
                }}
                classNames={`fade`}
                unmountOnExit
            >
                <div className={styles.wrapper}>
                    <PhotoGalleryLazy
                        closeHandler={() => toggleGallery(false)}
                        payload={galleryPayload as IParticipant}
                    />
                </div>
            </CSSTransition>
        </Provider>
    );
});

export const useGallery = () => useContext(GalleryContext);
