import { FC } from 'react';

import './tagRadioButton.scss';
import Skeleton from 'react-loading-skeleton';

const SkeletonTagRadioButton: FC = () => {
    return <Skeleton className="tag-radio-button" style={{ width: 100, borderRadius: '50px' }} />;
};

export default SkeletonTagRadioButton;
