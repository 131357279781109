import React from 'react';

import styles from './upRating.module.scss';

import Skeleton from 'react-loading-skeleton';
import SkeletonButton from '@/components/UI/Button/SkeletonButton';
import SkeletonTagRadioButton from '@/components/UI/TagRadioButton/SkeletonTagRadioButton';

const SkeletonUpRating: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <Skeleton height={24} width={'80%'} />
            </div>
            <div className={styles.coastList}>
                <SkeletonTagRadioButton />
                <SkeletonTagRadioButton />
                <SkeletonTagRadioButton />
            </div>
            <Skeleton className={styles.bottom} height={66} />

            <div className={styles.footer}>
                <SkeletonButton />
            </div>
        </div>
    );
};

export default SkeletonUpRating;
