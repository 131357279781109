import React, { FC, PropsWithChildren } from 'react';

import { Tooltip } from 'react-tooltip';

import 'react-tooltip/dist/react-tooltip.css';

interface ITooltipWrap extends PropsWithChildren {
    id: string;
    place?:
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'right'
        | 'right-start'
        | 'right-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'left'
        | 'left-start'
        | 'left-end';
}

const TooltipWrap: FC<ITooltipWrap> = ({ id, children, place = 'top' }) => {
    return (
        <Tooltip id={id} place={place} style={{ width: '80%' }}>
            {children}
        </Tooltip>
    );
};

export default TooltipWrap;
