import { CSSProperties, FC } from 'react';

import './button.scss';

import Skeleton from 'react-loading-skeleton';

interface ISkeletonButton {
    style?: CSSProperties | undefined;
    className?: string;
}

const SkeletonButton: FC<ISkeletonButton> = ({ style, className }) => {
    return (
        <div className={`btn-base ${className ? className : ''}`}>
            <Skeleton
                className={`btn`}
                style={{
                    background: 'var(--base-color)',
                    width: '100%',
                    boxSizing: 'border-box',
                    ...style,
                }}
            />
        </div>
    );
};

export default SkeletonButton;
