import { IGalleryPayload } from './services/Competiton.type';

export interface IModalContext {
    isOpenPopup: boolean;
    togglePopup: (flag: boolean, popupType?: PopupTypes, payload?: any, direction?: PopupDirection) => void;
}

export interface IGalleryContext {
    isOpenGallery: boolean;
    toggleGalleryHandler: (flag: boolean, payload?: IGalleryPayload) => void;
    galleryPayload: IGalleryPayload | null;
}

export enum PopupTypes {
    UpRating = 1,
    RejectParticipant = 2,
    SetUserSiteLink = 3,
    RemoveFromCompetition = 4,
    SponsorStars = 5,
}

export enum PopupDirection {
    TopToBottom,
    BottomToTop,
}
