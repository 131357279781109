import React, { FC } from 'react';

import styles from './removeFromCompetition.module.scss';
import Skeleton from 'react-loading-skeleton';
import SkeletonButton from '@/components/UI/Button/SkeletonButton';

const SkeletonRemoveFromCompetition: FC = () => {
    return (
        <div className={styles.removeFromCompetition}>
            <div className={styles.header}>
                <Skeleton height={23} />
                <span style={{ textAlign: 'center' }}>
                    <Skeleton height={15} style={{ width: '100%' }} />
                    <Skeleton height={15} style={{ width: '50%', marginTop: 10 }} />
                </span>
            </div>

            <div className={styles.controls}>
                <SkeletonButton />
                {/* <Button text={'Снять с конкурса'} /> */}

                <SkeletonButton />
            </div>
        </div>
    );
};

export default SkeletonRemoveFromCompetition;
