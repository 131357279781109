import { IUserPanelStore } from '@/lib/types/store/UserPanelStore';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useUserPanelStore = create<IUserPanelStore>()(
    devtools((set, get) => ({
        activeTab: 'contestList',
        setActiveTab: (tab) => {
            set({ activeTab: tab });
        },
    }))
);
