import React from 'react';

import Skeleton from 'react-loading-skeleton';

const InputSkeleton = () => {
    return (
        <Skeleton
            className={'base-input'}
            style={{
                minHeight: '51px',
                borderRadius: '10px',
            }}
        />
    );
};

export default InputSkeleton;
