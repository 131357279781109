import React from 'react';

import styles from './setUserSiteLink.module.scss';

import Skeleton from 'react-loading-skeleton';
import SkeletonButton from '@/components/UI/Button/SkeletonButton';
import InputSkeleton from '@/components/UI/Input/InputSkeleton';

const SkeletonSetUserSiteLink: React.FC = () => {
    return (
        <div className={styles.setUserSiteLinkModal}>
            <div className={styles.header}>
                <Skeleton height={45} />
                <span style={{ textAlign: 'center' }}>
                    <Skeleton height={15} style={{ width: '100%' }} />
                    <Skeleton height={15} style={{ width: '50%', marginTop: 10 }} />
                </span>
            </div>

            <form className={styles.content}>
                <InputSkeleton />
                <div className={styles.controls}>
                    <SkeletonButton />
                    <SkeletonButton />
                </div>
            </form>
        </div>
    );
};

export default SkeletonSetUserSiteLink;
